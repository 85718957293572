import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import { openPageViewModal } from './PageViewModalUtils'; // Import the modal opening utility
import { Asset } from './types'; // Import the Asset type

interface TurnoverData {
  id: string;
  date: string;
  action: string;
  talent: string;
  imageUrl?: string;  // New property to store the fetched image URL
}

interface Talent {
  token_address: string;
  token_id: string;
  name: string;
  owner: string;
  date_update: string;
  image_url: string;
  card_id: string;
  token_new_url?: string;  // Optional since it might be null
  card_experience?: number;
  card_force?: string;
  ETH_value?: string;
  EUR_value?: string;
  USD_value?: string;
  lineup?: number;
  total_lineup?: number;
  dollar_max?: number;
  euro_max?: number;
  eth_max?: number;
  buyer_max?: string;
  offers_count?: number;
  desired_eth?: number;
  desired_dollar?: number;
  desired_euro?: number;
  current_order?: number;
  pending?: number;
  toTransfer?: number;
  USD_ratio?: string;
  EUR_ratio?: string;
  ETH_ratio?: string;
  profile_new_url?: string;
  similar_cards_total?: number;
  similar_cards_on_sale?: number;
  similar_cards_total_pending?: number;
  lineup_per_talent?: number;
  available_cards?: number;
  potential_buyers?: string;
  total_pending?: number;
  country?: string;
  state?: string;
  city?: string;
  main_nationality?: string;
  main_activity?: string;
  main_category?: string;
  league?: string;
  league_url?: string;
  club?: string;
  club_url?: string;
  national_team?: string;
  national_team_url?: string;
  tag1?: string;
  tag2?: string;
  tag3?: string;
  tag4?: string;
  tag5?: string;
  prize1?: string;
  prize2?: string;
  prize3?: string;
  prize4?: string;
  prize5?: string;
  talent_age?: string;
  talent_name_original?: string;
  talent_first_name?: string;
  talent_last_name?: string;
  talent_instagram?: string;
  talent_youTube?: string;
  talent_facebook?: string;
  talent_tiktok?: string;
  talent_X?: string;  // For Twitter (previously known as Twitter/X)
  talent_pinterest?: string;
  talent_linkedIn?: string;
  talent_twitch?: string;
  talent_website?: string;
  talent_force?: string;
  talent_description?: string;
  instagram_followers?: string;
  talent_birthdate?: string;
  talent_experience?: string;
  json_data: Record<string, any>;
  current_score?: string;
  current_game?: string;
  card_xp_bonus?: string;
  card_force_bonus?: string;
  similar_cards_bonus?: string;
  final_score?: string;
  total_score?: string;
}

const TeamTurnover: React.FC = () => {
  const { t } = useTranslation();
  const [turnoverData, setTurnoverData] = useState<TurnoverData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [talentDetails, setTalentDetails] = useState<{ [key: string]: Talent | null }>({}); // Store fetched talents by name
  const walletAddress = localStorage.getItem('walletAddress'); // Retrieve walletAddress from localStorage

  const linkUrl = `https://${process.env.REACT_APP_KEY_STAGING || 'staging2'}.peaxel.me`;

  useEffect(() => {
    const fetchTurnoverData = async () => {
      try {
        const response = await fetch(`/wp-json/custom/v1/get-team-turnover/?wallet_address=${walletAddress}`);
        const data = await response.json();
        if (!response.ok) throw new Error('Failed to fetch data');
        setTurnoverData(data);
  
        // Fetch all talent details after turnover data is loaded
        await Promise.all(data.map(async (item: TurnoverData) => {
          await fetchTalentDetails(item.talent); // Fetch details for each talent to get the profile image
        }));
        
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
  
    if (walletAddress) {
      fetchTurnoverData();
    } else {
      setError('No wallet address found');
      setLoading(false);
    }
  }, [walletAddress]);
  

  // Function to fetch talent details based on turnover talent name
  const fetchTalentDetails = async (talentName: string) => {
    if (!talentDetails[talentName]) {
      try {
        const response = await fetch(
          `${linkUrl}/wp-json/custom/v1/custom-search?searchterm=${talentName.toLowerCase()}`
        );
        const data = await response.json();
        setTalentDetails((prevState) => ({
          ...prevState,
          [talentName]: data[0] || null, // Store first talent result, or null if not found
        }));
      } catch (err) {
        console.error(`Error fetching talent data for ${talentName}:`, err);
      }
    }
  };

  // Create an Asset from the fetched talent data to open in the modal
  const createAssetFromTalent = (talent: Talent) => {
    return {
        
            collection: {
              icon_url: '', // Using the profile picture as a fallback icon
              name: 'PEAXEL_INTRO_BIS', // Placeholder for collection name
            },
            created_at: new Date().toISOString(),
            description: talent.talent_description || '', // Description based on job and country
            id: talent.card_id,
            image_url: talent.image_url, // Using the profile picture as the main image
            metadata: {
              country: talent.country,
              description: talent.talent_description || '',
              name: talent.name,
              image_url: talent.token_new_url || '', // Reusing profile picture in metadata
            },
            name: talent.name,
            status: 'active', // Assuming a default active status
            token_address: talent.token_address, // Placeholder token address
            token_id: talent.token_id, // Placeholder token ID
            updated_at: new Date().toISOString(),
            uri: null,
            user: '', // Placeholder user
            talentCard: {
              token_address: talent.token_address,
              token_id: talent.token_id,
              token_new_url: talent.token_new_url || '', //
              card_id: talent.card_id,
              name: talent.name,
              owner: talent.owner,
              date_update: new Date().toISOString(),
              image_url: talent.token_new_url || '',
              card_experience: talent.card_experience || 0,
              card_force: talent.card_force || '',
              ETH_value: talent.ETH_value || '',
              EUR_value: talent.EUR_value || '',
              USD_value: talent.USD_value || '',
              lineup: 1,
              ETH_ratio: '',
              EUR_ratio: '',
              USD_ratio: '',
              buyer_max: '',
              current_order: '',
              desired_dollar: '',
              desired_eth: '',
              desired_euro: '',
              dollar_max: '',
              eth_max: '',
              euro_max: '',
              offers_count: '',
              pending: '',
              toTransfer: '',
              total_lineup: '',
              profile_new_url: talent.profile_new_url || '',
              similar_cards_total: 0,
              similar_cards_on_sale: 0,
              similar_cards_total_pending: 0,
              lineup_per_talent: 0,
              available_cards: 0,
              potential_buyers: '',
              total_pending: 0,
              country: talent.main_nationality || '',
              state: talent.state || '',
              city: talent.city || '',
              main_nationality: talent.main_nationality || '',
              main_activity: talent.main_activity || '',
              main_category: talent.main_category || '',
              league: talent.league || '',
              league_url: talent.league_url || '',
              club: talent.club || '',
              club_url: talent.club_url || '',
              national_team: talent.national_team || '',
              national_team_url: talent.national_team_url || '',
              tag1: talent.tag1 || '',
              tag2: talent.tag2 || '',
              tag3: talent.tag3 || '',
              tag4: talent.tag4 || '',
              tag5: talent.tag5 || '',
              prize1: talent.prize1 || '',
              prize2: talent.prize2 || '',
              prize3: talent.prize3 || '',
              prize4: talent.prize4 || '',
              prize5: talent.prize5 || '',
              talent_age: talent.talent_age || '',
              talent_name_original: talent.talent_name_original || '',
              talent_first_name: talent.talent_first_name || '',
              talent_last_name: talent.talent_last_name || '',
              talent_instagram: talent.talent_instagram || '',
              talent_youTube: talent.talent_youTube || '',
              talent_facebook: talent.talent_facebook || '',
              talent_tiktok: talent.talent_tiktok || '',
              talent_X: talent.talent_X || '',
              talent_pinterest: talent.talent_pinterest || '',
              talent_linkedIn: talent.talent_linkedIn || '',
              talent_twitch: talent.talent_twitch || '',
              talent_website: talent.talent_website || '',
              talent_force: talent.talent_force || '',
              talent_description: talent.talent_description || '',
              instagram_followers: talent.instagram_followers || '',
              talent_birthdate: talent.talent_birthdate || '',
              talent_experience: talent.talent_experience || '',
              json_data: talent.json_data || {},
              current_score: talent.current_score || '',
              current_game: talent.current_game || '',
              card_xp_bonus: talent.card_xp_bonus || '',
              card_force_bonus: talent.card_force_bonus || '',
              similar_cards_bonus: talent.similar_cards_bonus || '',
              final_score: talent.final_score || '',
              total_score: talent.total_score || '',
           
          }
    };
  };

  // Open the talent profile in a modal when clicking the talent name
  const handleProfileClick = useCallback(async (talentName: string) => {
    await fetchTalentDetails(talentName);
    const talent = talentDetails[talentName];
    if (talent) {
      const asset = createAssetFromTalent(talent);
      openPageViewModal(asset, '');
    }
  }, [talentDetails]);

  if (loading) {
    return <p>{t('Loading...')}</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="team-turnover">
      <div style={{
            height: '250px',
            overflow: 'scroll',
            scrollbarWidth: 'none',
            marginTop: '-10px',
      }}>
        {turnoverData
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) // Sort from most recent to oldest
          .map((item) => (
            <div key={item.id} className="turnover-item">
              <span className="turnover-head">
                {item.action === 'Join' ? (
                  <i style={{color: '#b1c71a', fontSize: '175%'}} className="fa-solid fa-circle-right" aria-hidden="true"></i>
                ) : (
                  <i style={{color: '#62002a', fontSize: '175%'}} className="fa-solid fa-circle-left" aria-hidden="true"></i>
                )}

                {/* Display the fetched image URL or fallback to the default image */}
                <img className="turnover-card" src={talentDetails[item.talent]?.profile_new_url} alt="talent" onClick={() => handleProfileClick(item.talent)} />
                <span className="turnover-talent" onClick={() => handleProfileClick(item.talent)} style={{ cursor: 'pointer' }}>
                  {item.talent}
                </span>
              </span>
              <span className="turnover-date">
                {new Date(item.date).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TeamTurnover;
